import React from 'react'
import { MdDashboard, MdOutlineBusinessCenter, MdSportsScore } from "react-icons/md";
import { GiInspiration, GiPodiumWinner } from "react-icons/gi";
import { GrCurrency, GrWorkshop } from "react-icons/gr";
import { FcBusinessman } from "react-icons/fc";
import { BsQuestionSquare } from 'react-icons/bs';
import { BiCategoryAlt } from 'react-icons/bi';

const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const ProductCategory = React.lazy(() => import("../pages/productCategory/ProductCategory"));
const Product = React.lazy(() => import("../pages/product/Product"));
const Business = React.lazy(() => import("../pages/business/Business"));
const Article = React.lazy(() => import("../pages/entrepreneur/Article"));
const Inspiration = React.lazy(() => import("../pages/inspiration/Inspiration"));
const NavigationResource = React.lazy(() => import("../pages/navigationResource/NavigationResource"));
const Country = React.lazy(() => import("../pages/country/Country"));
const Currency = React.lazy(() => import("../pages/currency/Currency"));
const Question = React.lazy(() => import("../pages/questions/Question"));
const Scoreboard = React.lazy(() => import("../pages/scoreboard/Scoreboard"));
const Winner = React.lazy(() => import("../pages/winner/Winner"));
const ProductSubCategory = React.lazy(() => import("../pages/productSubCategory/ProductSubCategory"));

export const adminRoutes = [
    {
        path: "/dashboard/data",
        element: <Dashboard />,
        name: "Dashboard",
        icon: <MdDashboard size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/category",
        element: <ProductCategory />,
        name: "Category",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/busCategory",
        element: <ProductSubCategory />,
        name: "Sub Category",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/product",
        element: <Product />,
        name: "Product",
        icon: <BiCategoryAlt size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/article",
        element: <Article />,
        name: "Article",
        icon: <GiInspiration size={"1.5em"} />,
        sideBarVisible: true,
    },
    // {
    //     path: "/dashboard/business",
    //     element: <Business />,
    //     name: "Business",
    //     icon: <MdOutlineBusinessCenter size={"1.5em"} />,
    //     sideBarVisible: true,
    // },
    {
        path: "/dashboard/country",
        element: <Country />,
        name: "Country",
        icon: <MdOutlineBusinessCenter size={"1.5em"} />,
        sideBarVisible: true,
    },
    {
        path: "/dashboard/currency",
        element: <Currency />,
        name: "Currency",
        icon: <GrCurrency size={"1.5em"} />,
        sideBarVisible: true
    },
    {
        path: "/dashboard/questions",
        element: <Question />,
        name: "Questions",
        icon: <BsQuestionSquare size={"1.5em"} />,
        sideBarVisible: true
    },
    {
        path: "/dashboard/scoreboard",
        element: <Scoreboard />,
        name: "Scoreboard",
        icon: <MdSportsScore size={"1.5em"} />,
        sideBarVisible: true
    },
    {
        path: "/dashboard/winner",
        element: <Winner />,
        name: "Winners",
        icon: <GiPodiumWinner size={"1.5em"} />,
        sideBarVisible: true
    }
];
